import React, { Component, ChangeEvent } from 'react';
import { Auth } from 'aws-amplify';
import TradeResource from './TradeResource';
import { LedgerAction, Piece, ResourceChange } from './Models';
import ResourceLedgerRow from './ResourceLedger';
import {Pieces} from './Constants';
import PieceRequisition from './PieceRequisition';

type ResourceCalculatorProps = {
  gameIdentifier: string,
}

type PieceRequisitionLedger = {
  piece: Piece,
  requisitionCount: number
}

type ResourceCalculatorState = {
  user: any,
  ledgerActionCount: number,
  ledgerActions: LedgerAction[],
  storedGrain: number,
  storedMeat: number,
  storedWood: number,
  storedStone: number,
  storedFish: number,
  lastGatheredGrain: number,
  lastGatheredMeat: number,
  lastGatheredWood: number,
  lastGatheredStone: number,
  lastGatheredFish: number,
  gatheredGrain: number,
  gatheredMeat: number,
  gatheredWood: number,
  gatheredStone: number,
  gatheredFish: number,
  availableGrain: number,
  availableMeat: number,
  availableWood: number,
  availableStone: number,
  availableFish: number,
  pieceRequisitions: PieceRequisitionLedger[],
  showEndCancel: boolean,
  showAllocateLedgerActions: boolean
}

export default class ResourceCalculator extends Component<ResourceCalculatorProps, ResourceCalculatorState> {
  static displayName = ResourceCalculator.name;

  constructor(props: ResourceCalculatorProps) {
    super(props);
    this.handleAddLedgerEntry = this.handleAddLedgerEntry.bind(this);

    this.handleStoredGrainChange = this.handleStoredGrainChange.bind(this);
    this.handleStoredMeatChange = this.handleStoredMeatChange.bind(this);
    this.handleStoredWoodChange = this.handleStoredWoodChange.bind(this);
    this.handleStoredStoneChange = this.handleStoredStoneChange.bind(this);
    this.handleStoredFishChange = this.handleStoredFishChange.bind(this);

    this.handleGatheredGrainChange = this.handleGatheredGrainChange.bind(this);
    this.handleGatheredMeatChange = this.handleGatheredMeatChange.bind(this);
    this.handleGatheredWoodChange = this.handleGatheredWoodChange.bind(this);
    this.handleGatheredStoneChange = this.handleGatheredStoneChange.bind(this);
    this.handleGatheredFishChange = this.handleGatheredFishChange.bind(this);
    this.handleCancelTrade = this.handleCancelTrade.bind(this);

    this.handleRequisition = this.handleRequisition.bind(this);
    this.handleCancelRequisition = this.handleCancelRequisition.bind(this);

    this.handleToggleEndCancel = this.handleToggleEndCancel.bind(this);
    this.handleEndTurn = this.handleEndTurn.bind(this);

    this.handleToggleShowAllocateLedgerActions = this.handleToggleShowAllocateLedgerActions.bind(this);

    this.handleReset = this.handleReset.bind(this);

    this.state = {
        user: null,
        ledgerActionCount: 0,
        ledgerActions: [],
        storedGrain: 0,
        storedMeat: 0,
        storedWood: 0,
        storedStone: 0,
        storedFish: 0,
        lastGatheredGrain: 0,
        lastGatheredMeat: 0,
        lastGatheredWood: 0,
        lastGatheredStone: 0,
        lastGatheredFish: 0,
        gatheredGrain: 0,
        gatheredMeat: 0,
        gatheredWood: 0,
        gatheredStone: 0,
        gatheredFish: 0,
        availableGrain: 0,
        availableMeat: 0,
        availableWood: 0,
        availableStone: 0,
        availableFish: 0,
        pieceRequisitions: Pieces.map(piece => ({ piece, requisitionCount: 0})),
        showEndCancel: false,
        showAllocateLedgerActions: false,
    };
  }

  handleAddLedgerEntry(ledgerReason: string, resourceChanges: ResourceChange[]) {
    this.setState((state) => {
        const newLedgerActionCount = state.ledgerActionCount + 1;
        const newEntry = {
              actionNumber: newLedgerActionCount,
              reason: ledgerReason,
              resourceChanges
          };

        var updatedLedger = state.ledgerActions;

        if (!updatedLedger || updatedLedger.length === 0)
        {
          updatedLedger = [newEntry];
        }
        else
        {
          updatedLedger.push(newEntry);
        }

        return { ledgerActions: updatedLedger, ledgerActionCount: newLedgerActionCount }
      });

    this.recalculateResources();
  }

  handleCancelTrade(actionNumber: number) {
    this.setState((state) => {
      return {ledgerActions: state.ledgerActions.filter(entry => entry.actionNumber !== actionNumber)}
    });

    this.recalculateResources();
  }

  recalculateResources() {
    this.recalculateAvailableGrain();
    this.recalculateAvailableMeat();
    this.recalculateAvailableWood();
    this.recalculateAvailableStone();
    this.recalculateAvailableFish();
  }

  handleStoredGrainChange(e: ChangeEvent<HTMLInputElement>) {
    this.setState({
        storedGrain: Number(e.target.value)
    });

    this.recalculateAvailableGrain();
  }

  handleGatheredGrainChange(e: ChangeEvent<HTMLInputElement>) {
    this.setState({
        gatheredGrain: Number(e.target.value)
    });

    this.recalculateAvailableGrain();
  }

  recalculateAvailableGrain() {
    this.setState((state) => {
      const ledgerTotalChange = state.ledgerActions
        .map(ledgerEntry => ledgerEntry.resourceChanges.filter(resourceChange => resourceChange.resource === 'grain'))
        .flat()
        .map(resourceChange => resourceChange.change)
        .reduce((total, change) => total + change, 0);

      return { availableGrain: state.storedGrain + state.gatheredGrain + ledgerTotalChange }
    });
  }

  handleStoredMeatChange(e: ChangeEvent<HTMLInputElement>) {
    this.setState({
        storedMeat: Number(e.target.value)
    });

    this.recalculateAvailableMeat();
  }

  handleGatheredMeatChange(e: ChangeEvent<HTMLInputElement>) {
    this.setState({
        gatheredMeat: Number(e.target.value)
    });

    this.recalculateAvailableMeat();
  }

  recalculateAvailableMeat() {
    this.setState((state) => {
      const ledgerTotalChange = state.ledgerActions
        .map(ledgerEntry => ledgerEntry.resourceChanges.filter(resourceChange => resourceChange.resource === 'meat'))
        .flat()
        .map(resourceChange => resourceChange.change)
        .reduce((total, change) => total + change, 0);

      return { availableMeat: state.storedMeat + state.gatheredMeat + ledgerTotalChange }
    });
  }

  handleStoredWoodChange(e: ChangeEvent<HTMLInputElement>) {
    this.setState({
        storedWood: Number(e.target.value)
    });

    this.recalculateAvailableWood();
  }

  handleGatheredWoodChange(e: ChangeEvent<HTMLInputElement>) {
    this.setState({
        gatheredWood: Number(e.target.value)
    });

    this.recalculateAvailableWood();
  }

  recalculateAvailableWood() {
    this.setState((state) => {
      const ledgerTotalChange = state.ledgerActions
        .map(ledgerEntry => ledgerEntry.resourceChanges.filter(resourceChange => resourceChange.resource === 'wood'))
        .flat()
        .map(resourceChange => resourceChange.change)
        .reduce((total, change) => total + change, 0);

      return { availableWood: state.storedWood + state.gatheredWood + ledgerTotalChange }
    });
  }

  handleStoredStoneChange(e: ChangeEvent<HTMLInputElement>) {
    this.setState({
        storedStone: Number(e.target.value)
    });

    this.recalculateAvailableStone();
  }

  handleGatheredStoneChange(e: ChangeEvent<HTMLInputElement>) {
    this.setState({
        gatheredStone: Number(e.target.value)
    });

    this.recalculateAvailableStone();
  }

  recalculateAvailableStone() {
    this.setState((state) => {
      const ledgerTotalChange = state.ledgerActions
        .map(ledgerEntry => ledgerEntry.resourceChanges.filter(resourceChange => resourceChange.resource === 'stone'))
        .flat()
        .map(resourceChange => resourceChange.change)
        .reduce((total, change) => total + change, 0);

      return { availableStone: state.storedStone + state.gatheredStone + ledgerTotalChange }
    });
  }

  handleStoredFishChange(e: ChangeEvent<HTMLInputElement>) {
    this.setState({
        storedFish: Number(e.target.value)
    });

    this.recalculateAvailableFish();
  }

  handleGatheredFishChange(e: ChangeEvent<HTMLInputElement>) {
    this.setState({
        gatheredFish: Number(e.target.value)
    });

    this.recalculateAvailableFish();
  }

  recalculateAvailableFish() {
    this.setState((state) => {
      const ledgerTotalChange = state.ledgerActions
        .map(ledgerEntry => ledgerEntry.resourceChanges.filter(resourceChange => resourceChange.resource === 'fish'))
        .flat()
        .map(resourceChange => resourceChange.change)
        .reduce((total, change) => total + change, 0);

      return { availableFish: state.storedFish + state.gatheredFish + ledgerTotalChange }
    });
  }

  handleRequisition(piece: Piece, useAlternateCost: boolean) {
    let ledgerReason = piece.name + " x 1";
    if (useAlternateCost && piece.alternateCost)
    {
      this.handleAddLedgerEntry(ledgerReason, piece.alternateCost);
    }
    else
    {
      this.handleAddLedgerEntry(ledgerReason, piece.cost);
    }

    this.setState((state) => {
      let pieceIndex = state.pieceRequisitions.findIndex(pieceEntry => pieceEntry.piece.name === piece.name);
      state.pieceRequisitions[pieceIndex].requisitionCount += 1;
      return { pieceRequisitions: state.pieceRequisitions}
    })

    this.recalculateResources();
  }

  handleCancelRequisition(pieceName: string) {
    this.setState((state) => {
      let lastPieceAddition = Math.max.apply(Math, state.ledgerActions.filter(entry => entry.reason.includes(pieceName)).map(entry => entry.actionNumber));
      return {ledgerActions: state.ledgerActions.filter(entry => entry.actionNumber !== lastPieceAddition)}
    });

    this.setState((state) => {
      let pieceIndex = state.pieceRequisitions.findIndex(pieceEntry => pieceEntry.piece.name === pieceName);
      state.pieceRequisitions[pieceIndex].requisitionCount -= 1;
      return { pieceRequisitions: state.pieceRequisitions}
    })

    this.recalculateResources();
  }

  handleToggleEndCancel() {
    this.setState((state) => {
      const currentShowEndCancel = state.showEndCancel;
      return { showEndCancel: !currentShowEndCancel }
    })
  }

  handleToggleShowAllocateLedgerActions() {
    this.setState((state) => {
      const currentShowAllocateLedgerActions = state.showAllocateLedgerActions;
      return { showAllocateLedgerActions: !currentShowAllocateLedgerActions }
    })
  }

  async handleEndTurn() {
    const storedResources = [{
      resourceIdentifier: "grain",
      count: this.state.storedGrain,
    },{
      resourceIdentifier: "meat",
      count: this.state.storedMeat,
    },{
      resourceIdentifier: "wood",
      count: this.state.storedWood,
    },{
      resourceIdentifier: "stone",
      count: this.state.storedStone,
    },{
      resourceIdentifier: "fish",
      count: this.state.storedFish,
    }];

    const gatheredResources = [{
      resourceIdentifier: "grain",
      count: this.state.gatheredGrain,
    },{
      resourceIdentifier: "meat",
      count: this.state.gatheredMeat,
    },{
      resourceIdentifier: "wood",
      count: this.state.gatheredWood,
    },{
      resourceIdentifier: "stone",
      count: this.state.gatheredStone,
    },{
      resourceIdentifier: "fish",
      count: this.state.gatheredFish,
    }];

    const availableResources = [{
      resourceIdentifier: "grain",
      count: this.state.availableGrain,
    },{
      resourceIdentifier: "meat",
      count: this.state.availableMeat,
    },{
      resourceIdentifier: "wood",
      count: this.state.availableWood,
    },{
      resourceIdentifier: "stone",
      count: this.state.availableStone,
    },{
      resourceIdentifier: "fish",
      count: this.state.availableFish,
    }];

    const turnEnded = {
      storedResources: storedResources,
      gatheredResources: gatheredResources,
      ledgerActions: this.state.ledgerActions.map(ledgerAction => { return {
        actionNumber: ledgerAction.actionNumber,
        description: ledgerAction.reason,
        resourceChanges: ledgerAction.resourceChanges.map(resourceChange => { return {
          resourceIdentifier: resourceChange.resource,
          count: resourceChange.change
        }}),
      }}),
      availableResources: availableResources,
    }

    if (!this.state.user)
    {
      let currentUser = await Auth.currentAuthenticatedUser();
      this.setState(
        {
          user: currentUser
        }
      );
    }

    if (!this.props.gameIdentifier)
    {
      this.handleReset();
      
      return;
    }

    const token = this.state.user.signInUserSession.idToken.jwtToken;

    const response = await fetch(`api/gameManager/game/${this.props.gameIdentifier}/turns`, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(turnEnded)
    });

    if (response.status !== 200)
    {
      console.log('Error ending turn: ' + response.status);
      return;
    }

    this.handleReset();
  }

  handleReset() {
    this.setState((state) => {
      return {
        ledgerActionCount: 0,
        ledgerActions: [],
        storedGrain: state.availableGrain,
        storedMeat: state.availableMeat,
        storedWood: state.availableWood,
        storedStone: state.availableStone,
        storedFish: state.availableFish,
        lastGatheredGrain: state.gatheredGrain,
        lastGatheredMeat: state.gatheredMeat,
        lastGatheredWood: state.gatheredWood,
        lastGatheredStone: state.gatheredStone,
        lastGatheredFish: state.gatheredFish,
        gatheredGrain: 0,
        gatheredMeat: 0,
        gatheredWood: 0,
        gatheredStone: 0,
        gatheredFish: 0,
        availableGrain: 0,
        availableMeat: 0,
        availableWood: 0,
        availableStone: 0,
        availableFish: 0,
        pieceRequisitions: Pieces.map(piece => ({ piece, requisitionCount: 0})),
        showEndCancel: false,
      }
    });

    this.recalculateResources();
  }

  render() {
    const tradeLedgerActions = this.state.ledgerActions
        .filter(entry => entry.reason.startsWith("trade"))
        .map(entry => <tr key={entry.actionNumber}>
          <ResourceLedgerRow key={entry.actionNumber + "tradeGrain"} resource="grain" ledgerAction={entry} onCancelTrade={this.handleCancelTrade} allowCancel={true} />
          <ResourceLedgerRow key={entry.actionNumber + "tradeMeat"} resource="meat" ledgerAction={entry} onCancelTrade={this.handleCancelTrade} allowCancel={true} />
          <ResourceLedgerRow key={entry.actionNumber + "tradeWood"} resource="wood" ledgerAction={entry} onCancelTrade={this.handleCancelTrade} allowCancel={true} />
          <ResourceLedgerRow key={entry.actionNumber + "tradeStone"} resource="stone" ledgerAction={entry} onCancelTrade={this.handleCancelTrade} allowCancel={true} />
          <ResourceLedgerRow key={entry.actionNumber + "tradeFish"} resource="fish" ledgerAction={entry} onCancelTrade={this.handleCancelTrade} allowCancel={true} />
          </tr>);

    const allocateLedgerActions = this.state.ledgerActions
      .filter(entry => !entry.reason.startsWith("trade"))
      .map(entry => <tr key={entry.actionNumber}>
        <ResourceLedgerRow key={entry.actionNumber + "allocateGrain"} resource="grain" ledgerAction={entry} onCancelTrade={this.handleCancelTrade} allowCancel={false} />
        <ResourceLedgerRow key={entry.actionNumber + "allocateMeat"} resource="meat" ledgerAction={entry} onCancelTrade={this.handleCancelTrade} allowCancel={false} />
        <ResourceLedgerRow key={entry.actionNumber + "allocateWood"} resource="wood" ledgerAction={entry} onCancelTrade={this.handleCancelTrade} allowCancel={false} />
        <ResourceLedgerRow key={entry.actionNumber + "allocateStone"} resource="stone" ledgerAction={entry} onCancelTrade={this.handleCancelTrade} allowCancel={false} />
        <ResourceLedgerRow key={entry.actionNumber + "allocateFish"} resource="fish" ledgerAction={entry} onCancelTrade={this.handleCancelTrade} allowCancel={false} />
        </tr>);

    return (
        <div>
        <h1>Resource Calculator</h1>
        {this.props.gameIdentifier ? <h3>Game: {this.props.gameIdentifier}</h3> : null}
        <table>
          <thead>
            <tr>
              <th className="grain"><em>G</em>rain</th>
              <th className="meat"><em>M</em>eat</th>
              <th className="wood"><em>W</em>ood</th>
              <th className="stone"><em>S</em>tone</th>
              <th className="fish"><em>F</em>ish</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th colSpan={5}>Stored</th>
            </tr>
            <tr>
              <td className="grain">
                <input className="input-amount" type="number" name="grainStoredAmt" value={this.state.storedGrain} onChange={this.handleStoredGrainChange} />
              </td>
              <td className="meat">
                <input className="input-amount" type="number" name="meatStoredAmt" value={this.state.storedMeat} onChange={this.handleStoredMeatChange} />
              </td>
              <td className="wood">
                <input className="input-amount" type="number" name="woodStoredAmt" value={this.state.storedWood} onChange={this.handleStoredWoodChange} />
              </td>
              <td className="stone">
                <input className="input-amount" type="number" name="stoneStoredAmt" value={this.state.storedStone} onChange={this.handleStoredStoneChange} />
              </td>
              <td className="fish">
                <input className="input-amount" type="number" name="fishStoredAmt" value={this.state.storedFish} onChange={this.handleStoredFishChange} />
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th colSpan={5}>Gathered</th>
            </tr>
            <tr>
              <td>{this.state.lastGatheredGrain}</td>
              <td>{this.state.lastGatheredMeat}</td>
              <td>{this.state.lastGatheredWood}</td>
              <td>{this.state.lastGatheredStone}</td>
              <td>{this.state.lastGatheredFish}</td>
            </tr>
            <tr>
              <td className="grain">
                <input className="input-amount" type="number" name="grainGatheredAmt" value={this.state.gatheredGrain} onChange={this.handleGatheredGrainChange} />
              </td>
              <td className="meat">
                <input className="input-amount" type="number" name="meatGatheredAmt" value={this.state.gatheredMeat} onChange={this.handleGatheredMeatChange} />
              </td>
              <td className="wood">
                <input className="input-amount" type="number" name="woodGatheredAmt" value={this.state.gatheredWood} onChange={this.handleGatheredWoodChange} />
              </td>
              <td className="stone">
                <input className="input-amount" type="number" name="stoneGatheredAmt" value={this.state.gatheredStone} onChange={this.handleGatheredStoneChange} />
              </td>
              <td className="fish">
                <input className="input-amount" type="number" name="fishGatheredAmt" value={this.state.gatheredFish} onChange={this.handleGatheredFishChange} />
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th colSpan={5}>Traded</th>
            </tr>
            <tr>
              <td>
                <TradeResource resource="grain"
                    availableGrain={this.state.availableGrain}
                    availableMeat={this.state.availableMeat}
                    availableWood={this.state.availableWood}
                    availableStone={this.state.availableStone}
                    availableFish={this.state.availableFish}
                    onTrade={this.handleAddLedgerEntry} />
              </td>
              <td>
                <TradeResource resource="meat"
                    availableGrain={this.state.availableGrain}
                    availableMeat={this.state.availableMeat}
                    availableWood={this.state.availableWood}
                    availableStone={this.state.availableStone}
                    availableFish={this.state.availableFish}
                    onTrade={this.handleAddLedgerEntry} />
              </td>
              <td>
                <TradeResource resource="wood"
                    availableGrain={this.state.availableGrain}
                    availableMeat={this.state.availableMeat}
                    availableWood={this.state.availableWood}
                    availableStone={this.state.availableStone}
                    availableFish={this.state.availableFish}
                    onTrade={this.handleAddLedgerEntry} />
              </td>
              <td>
                <TradeResource resource="stone"
                    availableGrain={this.state.availableGrain}
                    availableMeat={this.state.availableMeat}
                    availableWood={this.state.availableWood}
                    availableStone={this.state.availableStone}
                    availableFish={this.state.availableFish}
                    onTrade={this.handleAddLedgerEntry} />
              </td>
              <td className="fish" />
            </tr>
            {tradeLedgerActions}
          </tbody>
          <tbody>
            <tr>
              <th colSpan={5}>Available</th>
            </tr>
            <tr>
              <td className="grain">
                <input className={this.state.availableGrain < 0 ? "input-amount text-danger" : "input-amount" } type="number" name="grainStartAmt" value={this.state.availableGrain} readOnly />
              </td>
              <td className="meat">
                <input className={this.state.availableMeat < 0 ? "input-amount text-danger" : "input-amount" } type="number" name="meatStartAmt" value={this.state.availableMeat} readOnly />
              </td>
              <td className="wood">
                <input className={this.state.availableWood < 0 ? "input-amount text-danger" : "input-amount" } type="number" name="woodStartAmt" value={this.state.availableWood} readOnly />
              </td>
              <td className="stone">
                <input className={this.state.availableStone < 0 ? "input-amount text-danger" : "input-amount" } type="number" name="stoneStartAmt" value={this.state.availableStone} readOnly />
              </td>
              <td className="fish">
                <input className={this.state.availableFish < 0 ? "input-amount text-danger" : "input-amount" } type="number" name="fishStartAmt" value={this.state.availableFish} readOnly />
              </td>
            </tr>
            {this.state.showAllocateLedgerActions ? allocateLedgerActions : null }
          </tbody>
        </table>
        <div className="container">
          <div className="row">
            <div className="col"></div>
            <div className="col"><p />
            {this.state.showEndCancel
              ? <p><span className="block">This will clear the calculator and move the available resources up as stored.</span><button className="btn btn-success" onClick={this.handleEndTurn}>Confirm</button><button className="btn btn-secondary" onClick={this.handleToggleEndCancel}>Cancel</button></p>
              : <p>{ allocateLedgerActions.some(function(x) { return x }) ? <button className="btn btn-outline-secondary" onClick={this.handleToggleShowAllocateLedgerActions}>{this.state.showAllocateLedgerActions ? "Hide" : "Show"} Purchase Details</button> : null } <button className="btn btn-primary btn-lg" onClick={this.handleToggleEndCancel}>End Turn</button></p>
            }
            </div>
            <div className="col"></div>
          </div>
        </div>
        <div className="container">
          {this.state.pieceRequisitions.map(pieceRequisition => <PieceRequisition key={pieceRequisition.piece.name} piece={pieceRequisition.piece} requisitionCount={pieceRequisition.requisitionCount} onRequisition={this.handleRequisition} onCancelRequisition={this.handleCancelRequisition} />)}
        </div>
      </div>
    );
  }
}
