import React, { Component, ChangeEvent } from 'react';
import { ResourceChange } from './Models';

type TradeResourceSelectorProps = {
    resource: string,
    onTrade(ledgerReason: string, resourceChanges: ResourceChange[]): void,
    onCancelTrade(): void
}

type TradeResourceSelectorState = {
    selectedResource: string,
    currentCount: number
}

export default class TradeResourceSelector extends Component<TradeResourceSelectorProps, TradeResourceSelectorState> {
  static displayName = TradeResourceSelector.name;

  constructor(props: TradeResourceSelectorProps) {
    super(props);
    this.state = { currentCount: 0, selectedResource: '' };
    this.handleTrade = this.handleTrade.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSelectedResourceChange = this.handleSelectedResourceChange.bind(this);
  }

  handleTrade() {
    const ledgerReason = 'trade ' + this.state.selectedResource + ' for ' + this.props.resource;

    const addResource: ResourceChange = {
            resource: this.props.resource,
            change: 1
        };

    const removeResource: ResourceChange = {
            resource: this.state.selectedResource,
            change: this.state.selectedResource === 'fish' ? -2 : -4
        };
    
    this.props.onTrade(ledgerReason, [removeResource, addResource]);

    this.setState((state) => {
        return { currentCount: state.currentCount + 1 }
    });

    this.props.onCancelTrade();
  }

  handleCancel() {
      this.props.onCancelTrade();
  }

  handleSelectedResourceChange(e : ChangeEvent<HTMLInputElement>) {
      this.setState({
        selectedResource: e.target.value
      });
  }

  render() {
    return (
      <div>
        <fieldset>
            {
                this.props.resource !== 'grain' ? <label className="block"><input type="radio" name="trade" onChange={this.handleSelectedResourceChange} value="grain" />Grain (-4)</label> : null
            }
            {
                this.props.resource !== 'meat' ? <label className="block"><input type="radio" name="trade" onChange={this.handleSelectedResourceChange} value="meat" />Meat (-4)</label> : null
            }
            {
                this.props.resource !== 'wood' ? <label className="block"><input type="radio" name="trade" onChange={this.handleSelectedResourceChange} value="wood" />Wood (-4)</label> : null
            }
            {
                this.props.resource !== 'stone' ? <label className="block"><input type="radio" name="trade" onChange={this.handleSelectedResourceChange} value="stone" />Stone (-4)</label> : null
            }
            <label className="block"><input type="radio" name="trade" onChange={this.handleSelectedResourceChange} value="fish" />Fish (-2)</label>
        </fieldset>

        <button className="btn btn-primary" onClick={this.handleTrade}>Trade</button>
        <button className="btn btn-secondary" onClick={this.handleCancel}>Cancel</button>
      </div>
    );
  }
}
