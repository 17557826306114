import { Piece } from "./Models";

let Resources: string[] = [
    'grain',
    'meat',
    'wood',
    'stone',
    'fish'
];

export {Resources};

let Pieces: Piece[]  = [
    {
        name: "Settler",
        cost: [
            { resource: "grain", change: -2 },
            { resource: "meat", change: -2 },
            { resource: "wood", change: -2 },
            { resource: "stone", change: -2 }
        ],
        alternateCost: null,
        capability: {
            attackDescription: "",
            minAttackValue: 0,
            defenseDescription: "",
            minDefenseValue: 1,
            rangeDescription: "",
            maxRangeDistance: 0,
            movementDescription: "",
            maxMovementDistance: 1
        }
    },
    {
        name: "Archer",
        cost: [
            { resource: "grain", change: -3 },
            { resource: "meat", change: -3 },
            { resource: "wood", change: -2 }
        ],
        alternateCost: null,
        capability: {
            attackDescription: "",
            minAttackValue: 2,
            defenseDescription: "",
            minDefenseValue: 3,
            rangeDescription: "",
            maxRangeDistance: 2,
            movementDescription: "",
            maxMovementDistance: 0
        }
    },
    {
        name: "Castle Infantry to Archer",
        cost: [
            { resource: "grain", change: -2 },
            { resource: "meat", change: -2 },
            { resource: "wood", change: -2 }
        ],
        alternateCost: null,
        capability: {
            attackDescription: "",
            minAttackValue: 2,
            defenseDescription: "",
            minDefenseValue: 3,
            rangeDescription: "",
            maxRangeDistance: 2,
            movementDescription: "",
            maxMovementDistance: 0
        }
    },
    {
        name: "Infantry",
        cost: [
            { resource: "grain", change: -1 },
            { resource: "meat", change: -1 }
        ],
        alternateCost: null,
        capability: {
            attackDescription: "",
            minAttackValue: 1,
            defenseDescription: "",
            minDefenseValue: 2,
            rangeDescription: "",
            maxRangeDistance: 1,
            movementDescription: "",
            maxMovementDistance: 2
        }
    },
    {
        name: "Cavalry",
        cost: [
            { resource: "grain", change: -2 },
            { resource: "meat", change: -2 }
        ],
        alternateCost: null,
        capability: {
            attackDescription: "",
            minAttackValue: 3,
            defenseDescription: "",
            minDefenseValue: 1,
            rangeDescription: "",
            maxRangeDistance: 1,
            movementDescription: "",
            maxMovementDistance: 3
        }
    },
    {
        name: "Artillery",
        cost: [
            { resource: "wood", change: -2 },
            { resource: "stone", change: -2 }
        ],
        alternateCost: null,
        capability: {
            attackDescription: "",
            minAttackValue: 3,
            defenseDescription: "",
            minDefenseValue: 1,
            rangeDescription: "",
            maxRangeDistance: 2,
            movementDescription: "",
            maxMovementDistance: 1
        }
    },
    {
        name: "Ship",
        cost: [
            { resource: "wood", change: -3 },
            { resource: "stone", change: -3 }
        ],
        alternateCost: null,
        capability: {
            attackDescription: "",
            minAttackValue: 3,
            defenseDescription: "",
            minDefenseValue: 2,
            rangeDescription: "",
            maxRangeDistance: 2,
            movementDescription: "",
            maxMovementDistance: 1
        }
    },
    {
        name: "Road",
        cost: [
            { resource: "wood", change: -1 },
            { resource: "stone", change: -3 }
        ],
        alternateCost: [
            { resource: "fish", change: -3 }
        ],
        capability: {
            attackDescription: "",
            minAttackValue: 0,
            defenseDescription: "",
            minDefenseValue: 0,
            rangeDescription: "",
            maxRangeDistance: 0,
            movementDescription: "Reduces by 1/2",
            maxMovementDistance: 0
        }
    },
    {
        name: "Bridge",
        cost: [
            { resource: "wood", change: -2 },
            { resource: "stone", change: -6 }
        ],
        alternateCost: [
            { resource: "fish", change: -6 }
        ],
        capability: {
            attackDescription: "",
            minAttackValue: 0,
            defenseDescription: "",
            minDefenseValue: 0,
            rangeDescription: "",
            maxRangeDistance: 0,
            movementDescription: "Reduces by 1/2",
            maxMovementDistance: 0
        }
    },
    {
        name: "Rough Terrain Road",
        cost: [
            { resource: "wood", change: -2 },
            { resource: "stone", change: -6 }
        ],
        alternateCost: [
            { resource: "fish", change: -6 }
        ],
        capability: {
            attackDescription: "",
            minAttackValue: 0,
            defenseDescription: "",
            minDefenseValue: 0,
            rangeDescription: "",
            maxRangeDistance: 0,
            movementDescription: "Reduces by 1/2",
            maxMovementDistance: 0
        }
    },
    {
        name: "Castle",
        cost: [
            { resource: "grain", change: -2 },
            { resource: "meat", change: -2 },
            { resource: "wood", change: -3 },
            { resource: "stone", change: -3 }
        ],
        alternateCost: null,
        capability: {
            attackDescription: "",
            minAttackValue: 0,
            defenseDescription: "",
            minDefenseValue: 2,
            rangeDescription: "",
            maxRangeDistance: 0,
            movementDescription: "",
            maxMovementDistance: 0
        }
    },
    {
        name: "Farm",
        cost: [
            { resource: "grain", change: -2 },
            { resource: "wood", change: -2 }
        ],
        alternateCost: null,
        capability: {
            attackDescription: "",
            minAttackValue: 0,
            defenseDescription: "",
            minDefenseValue: 0,
            rangeDescription: "",
            maxRangeDistance: 0,
            movementDescription: "",
            maxMovementDistance: 0
        }
    },
    {
        name: "Ranch",
        cost: [
            { resource: "meat", change: -2 },
            { resource: "wood", change: -2 }
        ],
        alternateCost: null,
        capability: {
            attackDescription: "",
            minAttackValue: 0,
            defenseDescription: "",
            minDefenseValue: 0,
            rangeDescription: "",
            maxRangeDistance: 0,
            movementDescription: "",
            maxMovementDistance: 0
        }
    },
    {
        name: "Barracks",
        cost: [
            { resource: "grain", change: -1 },
            { resource: "meat", change: -1 },
            { resource: "wood", change: -2 },
            { resource: "stone", change: -2 }
        ],
        alternateCost: null,
        capability: {
            attackDescription: "",
            minAttackValue: 0,
            defenseDescription: "",
            minDefenseValue: 2,
            rangeDescription: "",
            maxRangeDistance: 0,
            movementDescription: "",
            maxMovementDistance: 0
        }
    },
    {
        name: "Entrenchment",
        cost: [
            { resource: "wood", change: -2 },
            { resource: "stone", change: -1 }
        ],
        alternateCost: null,
        capability: {
            attackDescription: "Min +1",
            minAttackValue: 0,
            defenseDescription: "",
            minDefenseValue: 0,
            rangeDescription: "",
            maxRangeDistance: 0,
            movementDescription: "",
            maxMovementDistance: 0
        }
    },
    {
        name: "Card",
        cost: [
            { resource: "fish", change: -10 }
        ],
        alternateCost: null,
        capability: {
            attackDescription: "*",
            minAttackValue: 0,
            defenseDescription: "*",
            minDefenseValue: 0,
            rangeDescription: "*",
            maxRangeDistance: 0,
            movementDescription: "*",
            maxMovementDistance: 0
        }
    },
]

export {Pieces};