import React, { Component } from 'react';
import { ResourceChange } from './Models';
import TradeResourceSelector from './TradeResourceSelector';

type TradeResourceProps = {
  resource: string,
  availableGrain: number,
  availableMeat: number,
  availableWood: number,
  availableStone: number,
  availableFish: number,
  onTrade(ledgerReason: string, resourceChanges: ResourceChange[]): void
}

type TradeResourceState = {
  showSelector: boolean
}

export default class TradeResource extends Component<TradeResourceProps, TradeResourceState> {
  static displayName = TradeResource.name;

  constructor(props: TradeResourceProps) {
    super(props);
    this.state = { showSelector: false };
    this.handleStartTrade = this.handleStartTrade.bind(this);
    this.handleCancelTrade = this.handleCancelTrade.bind(this);
  }

  handleCancelTrade() {
    this.setState({
      showSelector: false
    });
  }

  handleStartTrade() {
    this.setState({
      showSelector: true
    });
  }

  render() {
    return (
      <div className={this.props.resource + " text-center"}>
        {
          this.state.showSelector ?
            <TradeResourceSelector resource={this.props.resource}
              onCancelTrade={this.handleCancelTrade}
              onTrade={this.props.onTrade} />
            : <button className="btn btn-primary" onClick={this.handleStartTrade}>Get</button>
        }
      </div>
    );
  }
}
