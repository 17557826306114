import React, { Component } from 'react';
import { Resources } from './Constants';
import { Piece } from './Models';

type PieceRequisitionProps = {
    piece: Piece,
    requisitionCount: number,
    onRequisition(piece: Piece, useAlternateCost: boolean): void,
    onCancelRequisition(pieceName: string): void
}

type PieceRequisitionState = {
  showCost: boolean,
  showUseAlternateCostChoice: boolean
}

export default class PieceRequisition extends Component<PieceRequisitionProps, PieceRequisitionState> {
  static displayName = PieceRequisition.name;

  constructor(props: PieceRequisitionProps) {
    super(props);

    this.state = {
      showCost: false,
      showUseAlternateCostChoice: false
    };

    this.handleRequisition = this.handleRequisition.bind(this);
    this.handleUseAlternateCostRequisition = this.handleUseAlternateCostRequisition.bind(this);
    this.handleCancelRequisition = this.handleCancelRequisition.bind(this);
    this.handleShowHideCost = this.handleShowHideCost.bind(this);
    this.handleShowHideUseAlternateCost = this.handleShowHideUseAlternateCost.bind(this);
  }

  handleRequisition() {
    // use normal cost if there's no option for alternate
    if (!!!this.props.piece.alternateCost)
    {
      this.props.onRequisition(this.props.piece, false);
      return;
    }

    // if the choice was available and made to use normal
    if (this.state.showUseAlternateCostChoice)
    {
      this.props.onRequisition(this.props.piece, false);
    }
    this.handleShowHideUseAlternateCost()
  }

  handleUseAlternateCostRequisition() {
    this.props.onRequisition(this.props.piece, true);
    this.handleShowHideUseAlternateCost()
  }

  handleCancelRequisition() {
    this.props.onCancelRequisition(this.props.piece.name);
  }

  handleShowHideCost() {
    this.setState((state) => {
      return { showCost: !state.showCost }
    });
  }

  handleShowHideUseAlternateCost() {
    this.setState((state) => {
      return { showUseAlternateCostChoice: !state.showUseAlternateCostChoice }
    });
  }

  render() {
    const resourceCosts = Resources.map(resourceName => this.props.piece.cost.some(resourceCost => resourceCost.resource === resourceName) ? <td key={this.props.piece.name + resourceName} className={resourceName}>{this.props.piece.cost.filter(resourceCost => resourceCost.resource === resourceName)[0].change}</td> : <td key={this.props.piece.name + resourceName} className={resourceName}>-</td>)
    const alternateResourceCosts = this.props.piece.alternateCost ? Resources.map(resourceName => this.props.piece.alternateCost?.some(resourceCost => resourceCost.resource === resourceName) ? <td key={'altCost' + this.props.piece.name + resourceName} className={resourceName}>{this.props.piece.alternateCost.filter(resourceCost => resourceCost.resource === resourceName)[0].change}</td> : <td key={'altCost' + this.props.piece.name + resourceName} className={resourceName}>-</td>) : null;
    return (
        <div className="row">
            <h4 className="col-4 text-primary" onClick={this.handleShowHideCost}>{this.props.piece.name}</h4>
            <div className="col"><input className="input-amount" type="number" key={this.props.piece.name + "Count"} value={this.props.requisitionCount} readOnly /></div>
            <div className="col">
                {this.props.requisitionCount > 0 ? 
                <span>
                    <svg onClick={this.handleCancelRequisition} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="darkred" className="bi bi-dash-square-fill" viewBox="0 0 16 16">
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1z"/>
                    </svg>
                    &nbsp;
                    </span>
                 : null}
                 {!this.state.showUseAlternateCostChoice ?
                <svg onClick={this.handleRequisition} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="green" className="bi bi-plus-square-fill" viewBox="0 0 16 16">
                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z"/>
                </svg> : null }
                {this.state.showUseAlternateCostChoice ? <span>Pay using alternative?<button onClick={this.handleUseAlternateCostRequisition} className="btn btn-warning btn-sm">Yes</button><button onClick={this.handleRequisition} className="btn btn-primary btn-sm">No</button><button onClick={this.handleShowHideUseAlternateCost} className="btn btn-secondary btn-sm">Cancel</button></span> : null }
            </div>
            <table className={this.state.showCost ? "collapse show" : "collapse"}>
              <tbody>
                <tr className="text-center">{resourceCosts}</tr>
                {alternateResourceCosts ? <tr className="text-center"><td colSpan={Resources.length}>- alternatively -</td></tr> : null}
                {alternateResourceCosts ? <tr className="text-center">{alternateResourceCosts}</tr> : null}
              </tbody>
            </table>
        </div>
    );
  }
}
