import React, { Component } from 'react';
import { LedgerAction } from './Models';

type ResourceLedgerRowProps = {
  ledgerAction: LedgerAction,
  resource: string,
  allowCancel: boolean,
  onCancelTrade(actionNumber: number): void
}

export default class ResourceLedgerRow extends Component<ResourceLedgerRowProps> {
  static displayName = ResourceLedgerRow.name;

  constructor(props: ResourceLedgerRowProps) {
    super(props);
    this.handleCancelTrade = this.handleCancelTrade.bind(this);
  }

  handleCancelTrade() {
    this.props.onCancelTrade(this.props.ledgerAction.actionNumber);
  }

  render() {
    return (
        <td>
            {
          this.props.ledgerAction.resourceChanges.some(resourceChange => resourceChange.resource === this.props.resource) ?
            this.props.allowCancel ? <span onClick={this.handleCancelTrade}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="darkred" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
          </svg> {this.props.ledgerAction.reason}</span>
          : <span>{this.props.ledgerAction.reason}</span>
            : <span>-</span>
        }
        </td>
    );
  }
}
