import { Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export default class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.authenticated = this.authenticated.bind(this);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.toggleShowLoginSignupModel = this.toggleShowLoginSignupModel.bind(this);
    this.createGame = this.createGame.bind(this);
    this.signOut = this.signOut.bind(this);
    this.state = {
      user: null,
      collapsed: true,
      showLoginSignupModel: false,
    };
  }

  authenticated() {
    this.populateUser();
  }

  componentDidMount() {
    this.populateUser();
  }

  componentWillUnmount() {
    this.setState({
      createdGameId: null
    });
  }

  async populateUser() {
    const user = await Auth.currentAuthenticatedUser();
    if (user)
    {
      this.setState({ user: user, showLoginSignupModel: false });
    }
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  toggleShowLoginSignupModel () {
    this.setState({
      showLoginSignupModel: !this.state.showLoginSignupModel
    })
  }

  async createGame () {
    const token = this.state.user.signInUserSession.idToken.jwtToken;

    const response = await fetch('api/gameManager/game', {
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    if (response.status !== 200)
    {
      console.log('Error creating game: ' + response.status);
      return;
    }

    const newGameId = await response.text();
    // const newGameId = "123-456-789";

    this.props.onCurrentGameChange(newGameId);

    // since create game was available, the navbar was expanded so lets collapse it
    this.toggleNavbar();
  }

  async signOut () {
    Auth.signOut().then(() => {
      this.setState({
        user: null
      });
    });
  }

  render () {
    let playerNav;
    let authenticationNav;

    if (this.state.user)
    {
      playerNav = <div>Player: {this.state.user.attributes.preferred_username}&nbsp;</div>
      authenticationNav = <button onClick={this.signOut}>Sign out</button>
    }
    else
    {
      authenticationNav = <button onClick={this.toggleShowLoginSignupModel}>Login/Signup</button>
    }

    let loginSignupModel;

    if (this.state.showLoginSignupModel)
    {
      loginSignupModel = <Authenticator variation="modal" signUpAttributes={['preferred_username']}>{() => (<h1>{this.authenticated()}</h1>)}</Authenticator>
    }

    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container>
            {loginSignupModel}
            <NavbarBrand tag={Link} to="/">Clash Companion</NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/game-lobby" onClick={this.createGame} disabled={!this.state.user}>Create Game</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/game-join" onClick={this.toggleNavbar} disabled={!this.state.user}>Join Game...</NavLink>
                </NavItem>
              </ul>
              {authenticationNav}
              {playerNav}
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
