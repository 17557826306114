import React, { Component, ChangeEvent } from 'react';
import { Auth } from 'aws-amplify';
import { Redirect } from 'react-router-dom';

type JoinGameProps = {
  onCurrentGameChange(newGameId: string): void
}

type JoinGameState = {
  user: any,
  suppliedGameIdentifier: string,
  joinFailureReason: string|null,
  gameJoinedSuccessfully: boolean,
}

export default class JoinGame extends Component<JoinGameProps, JoinGameState> {
  static displayName = JoinGame.name;

  constructor(props: JoinGameProps) {
    super(props);

    this.state = {
      user: null,
      suppliedGameIdentifier: "",
      joinFailureReason: null,
      gameJoinedSuccessfully: false,
    };

    this.handleJoinGame = this.handleJoinGame.bind(this);
    this.handleGameIdentifierSupplied = this.handleGameIdentifierSupplied.bind(this);
  }

  handleGameIdentifierSupplied(e : ChangeEvent<HTMLInputElement>) {
    this.setState({
      suppliedGameIdentifier: e.target.value
    });
  }


  async handleJoinGame () {
    if (!this.state.user)
    {
      let currentUser = await Auth.currentAuthenticatedUser();
      this.setState(
        {
          user: currentUser
        }
      );
    }

    var newGameId = this.state.suppliedGameIdentifier;
    const token = this.state.user.signInUserSession.idToken.jwtToken;

    const response = await fetch(`api/gameManager/game/${newGameId}/players`, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    if (response.status !== 200)
    {
      console.log('Error adding player to game: ' + response.status);

      this.setState({
        joinFailureReason: "Game not found."
      });

      return;
    }

    this.props.onCurrentGameChange(newGameId);

    this.setState({
      gameJoinedSuccessfully: true
    });
  }

  render() {
    if (this.state.gameJoinedSuccessfully)
    {
      return <Redirect to="/game-lobby" />
    }

    return (
      <div>
        <h1 id="tabelLabel" >Join Game:</h1>
        <input type="text" id="message" name="message" onChange={this.handleGameIdentifierSupplied} value={this.state.suppliedGameIdentifier} />
        <button onClick={this.handleJoinGame}>Join</button>
        { this.state.joinFailureReason ? <p><em>{this.state.joinFailureReason}</em></p> : null }
      </div>
    );
  }
}
