import { Amplify } from 'aws-amplify';
// import { Authenticator } from '@aws-amplify/ui-react';
import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import GameLobby from './components/Game/GameLobby';
import JoinGame from './components/Game/JoinGame';

import '@aws-amplify/ui-react/styles.css';
import './custom.css'

Amplify.configure({
  Auth: {
      region: 'us-east-2',
      userPoolId: 'us-east-2_yUX5yh5gA',
      userPoolWebClientId: '32n5e2onuivsjm8f8k1mtk5b4f',
      mandatorySignIn: false,

      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
          // domain: 'game-companion-temp-1000786126.us-east-2.elb.amazonaws.com',
          // domain: 'localhost',
          domain: 'clash-game-companion.com',
      // OPTIONAL - Cookie path
          path: '/',
      // OPTIONAL - Cookie expiration in days
          expires: 365,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
          sameSite: "lax",
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
          secure: true
      },

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: 'USER_SRP_AUTH',

      // OPTIONAL - Hosted UI configuration
      oauth: {
          domain: 'https://game-companion.auth.us-east-2.amazoncognito.com',
          scope: ['email', 'openid'],
          redirectSignIn: 'https://clash-game-companion.com/',
          redirectSignOut: 'https://clash-game-companion.com/',
          responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      }
  }
});

export default class App extends Component {
  static displayName = App.name;

  constructor (props) {
    super(props);

    this.changeCurrentGame = this.changeCurrentGame.bind(this);

    this.state = {
      currentGameId: null,
    };
  }

  changeCurrentGame (newGameId) {
    this.setState({
      currentGameId: newGameId
    });
  }

  // render () {
  //   return (
  //     <Authenticator signUpAttributes={['preferred_username']}>
  //       <Layout>
  //           <Route exact path='/' component={Home} />
  //           <Route path='/counter' component={Counter} />
  //           <Route path='/fetch-data' component={FetchData} />
  //         </Layout>
  //     </Authenticator>
  //   );
  // }
  render () {
    return (
      <Layout onCurrentGameChange={this.changeCurrentGame}>
        <Route exact path='/'>
          <Home gameIdentifier={this.state.currentGameId} />
        </Route>
        <Route path='/counter' component={Counter} />
        <Route path='/fetch-data' component={FetchData} />
        <Route path='/game-lobby'>
          <GameLobby gameIdentifier={this.state.currentGameId} />
        </Route>
        <Route path='/game-join'>
          <JoinGame onCurrentGameChange={this.changeCurrentGame} />
        </Route>
      </Layout>
    );
  }
}
