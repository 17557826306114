import React, { Component } from 'react';
import { Auth } from 'aws-amplify';

export type Game = {
  gameIdentifier: string,
  createdDate: Date,
  playersAreSet: boolean,
  ownerId: string,
  playerCount: number,
  currentPlayerId: string,
  players: GamePlayer[],
}

export type GamePlayer = {
  userId: string,
  userPreferredName: string,
  turnOrder: number,
}

export type GamePlayerTurnOrder = {
  userId: string,
  userPreferredName: string,
  turnOrder: number, 
}

type GameLobbyProps = {
  gameIdentifier: string
}

type GameLobbyState = {
  user: any,
  loading: boolean,
  game: Game|null,
  gamePlayerTurnOrders: GamePlayerTurnOrder[]|null,
}

export default class GameLobby extends Component<GameLobbyProps, GameLobbyState> {
  static displayName = GameLobby.name;

  constructor(props: GameLobbyProps) {
    super(props);

    this.state = {
      user: null,
      loading: true,
      game: null,
      gamePlayerTurnOrders: null
    };

    this.populateGameData = this.populateGameData.bind(this);
    this.removePlayer = this.removePlayer.bind(this);
    this.addPlayersToTurnOrder = this.addPlayersToTurnOrder.bind(this);
    this.startGame = this.startGame.bind(this);
  }

  async componentDidMount() {
    this.populateGameData();
  }

  async componentDidUpdate() {
    if (!this.state.game)
    {
      this.populateGameData();
    }
  }

  async populateGameData() {
    if (!this.state.user)
    {
      let currentUser = await Auth.currentAuthenticatedUser();
      this.setState(
        {
          user: currentUser
        }
      );
    }

    if (!this.props.gameIdentifier)
    {
      return;
    }

    const token = this.state.user.signInUserSession.idToken.jwtToken;

    // const response = await fetch(`https://localhost:49161/api/gameManager/game/52b59d3c-4dd3-4b72-aaa5-4a0012850d9b`, {
    const response = await fetch(`api/gameManager/game/${this.props.gameIdentifier}`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    if (response.status !== 200)
    {
      console.log('Error getting game: ' + response.status);
      return;
    }

    const data = await response.json();
    this.addPlayersToTurnOrder(data);
  }

  addPlayersToTurnOrder(game: Game) {
    this.setState((state) => {
      const currentTurnOrder = state.gamePlayerTurnOrders ?? [];
      
      var updatedTurnOrder = currentTurnOrder;

      // remove those in current, if any, which aren't in players
      if (updatedTurnOrder && currentTurnOrder)
      {
        const removedPlayers = currentTurnOrder.filter(turnPlayer => !game.players.some(player => player.userId === turnPlayer.userId));

        updatedTurnOrder = updatedTurnOrder.filter(turnPlayer => !removedPlayers.includes(turnPlayer));

        var existingPlayerCount = updatedTurnOrder.length;

        // re-order turn orders for remaining in current
        if (existingPlayerCount > 0)
        {
          updatedTurnOrder = updatedTurnOrder
            .sort((a, b) => a.turnOrder - b.turnOrder)
            .map((playerTurn, index) => ({
                userId: playerTurn.userId,
                userPreferredName: playerTurn.userPreferredName,
                turnOrder: index + 1 // assume index is zero based
              }))
        }
      }

      // add new players with new turn orders
      var startingTurnOrderForNewPlayers = updatedTurnOrder.length ?? 0;
      var newPlayersToAdd = game.players.filter(player => !updatedTurnOrder.some(turnPlayer => turnPlayer.userId === player.userId));

      if (newPlayersToAdd.length > 0)
      {
        newPlayersToAdd.map(newPlayer => {
          let newTurn = ({
            userId: newPlayer.userId,
            userPreferredName: newPlayer.userPreferredName,
            turnOrder: startingTurnOrderForNewPlayers += 1
          });
          updatedTurnOrder?.push(newTurn);
          return 1;
        })
      }

      return { gamePlayerTurnOrders: updatedTurnOrder, game: game, loading: false }
    });
  }

  async removePlayer(playerIdToRemove: string) {
    if (!this.props.gameIdentifier)
    {
      return;
    }

    const token = this.state.user.signInUserSession.idToken.jwtToken;

    const response = await fetch(`api/gameManager/game/${this.props.gameIdentifier}/players/${playerIdToRemove}`, {
      method: 'delete',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    if (response.status !== 200)
    {
      console.log('Error removing player: ' + response.status);
      return;
    }

    this.populateGameData();
  }

  async startGame() {
    const token = this.state.user.signInUserSession.idToken.jwtToken;

    const response = await fetch(`api/gameManager/game/${this.props.gameIdentifier}/start`, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(this.state.gamePlayerTurnOrders)
    });

    if (response.status !== 200)
    {
      console.log('Error creating game: ' + response.status);
      return;
    }

    this.populateGameData();
  }

  static renderGameTable(currentUserId: string, game: Game|null, turnOrder: GamePlayerTurnOrder[]|null, removePlayer: Function, startGame: Function) {
    if (!game)
    {
      return;
    }

    const userIsOwnerOfCurrentGame = game.ownerId === currentUserId;

    let playersView;

    const gameManagementIsEditable = userIsOwnerOfCurrentGame && turnOrder && !game.playersAreSet;

    if (gameManagementIsEditable && turnOrder)
    {
      playersView = turnOrder.map(player =>
        <tr key={player.userId}>
          <td>{player.turnOrder}</td>
          <td>{player.userPreferredName}</td>
          <td><button onClick={() => removePlayer(player.userId)}>Remove</button></td>
        </tr>
      );
    }
    else
    {
      playersView = game.players.map(player =>
        <tr key={player.userId}>
          <td>{player.turnOrder}</td>
          <td>{player.userPreferredName}</td>
          <td />
        </tr>
      );
    }

    return (
      <div>
        <table className='table table-striped' aria-labelledby="tabelLabel">
          <thead>
            <tr>
              <th>Turn</th>
              <th>Player</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {playersView}
          </tbody>
        </table>
        { gameManagementIsEditable ? <button onClick={() => startGame()}>Start</button> : null }
      </div>
    );
  }

  render() {
    let userId;

    if (this.state.user)
    {
      userId = this.state.user.username;
    }

    let contents;
    
    if (!this.props.gameIdentifier)
    {
        contents = <p><em>No current game</em></p>
    }
    else
    {
      contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : GameLobby.renderGameTable(userId, this.state.game, this.state.gamePlayerTurnOrders, this.removePlayer, this.startGame);
    }

    return (
      <div>
        <h1 id="tabelLabel" >Game Lobby: {this.props.gameIdentifier}</h1>
        <button onClick={this.populateGameData}>Refresh</button>
        {contents}
      </div>
    );
  }
}
